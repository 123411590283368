<template>
  <div class="container-sm" :class="{'mobile': device == 'mobile'}">
    <div :style="{ '--rootColor': rootColor || '#0A64A1' }">
      <el-timeline>
        <el-timeline-item
          v-for="(item, index) in listArr"
          :key="index"
          :timestamp="item.remark"
          placement="top"
          :color="rootColor || '#0A64A1'"
          :class="animation"
          :style="{animationDelay: (index + 1)*0.3 + 's'}"
        >
          <span class="inline-block line-height40 f16 pr20">{{ item.title }}</span>
          <!-- <span
            v-for="(honor, index) in item.title"
            :key="index"
            class="inline-block line-height40 f16 pr20"
            >{{ honor }}</span 
          >-->
        </el-timeline-item>
      </el-timeline>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeList",
  props: {
    rootColor: String,
    year: String,
    list: Array,
    // 列表所属栏目id
    catId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      listArr: [],

      animation: '',
    };
  },
  watch: {
    catId: {
      handler: function(val) {
        if(!val) return
        this.getData()
      },
      immediate: true,
    }
  },
  created: function() {
    
  },
  methods: {
    getData: function() {
      this.animation = ''
      this.$http.get('/article/article/list', { params: {categoryId: this.catId, auditStatus: 2,} }).then(res => {
        let data = res.rows || []
        data.sort((a, b) => {return a.createDate >= b.createDate? -1 : 1})
        data = data.map(item => {
          item.date = item.createDate.slice(8, 10);
          item.month = item.createDate.slice(0, 7);
          return item
        });
        this.listArr = data 
        // console.log(this.listArr)

        this.animation = 'animation-slide-right'
      })
    },
  },
};
</script>


<style lang="scss" scoped>
@import "~@/styles/variables.scss";
.line-height40 {
  line-height: 40px;
}
::v-deep {
  .el-timeline-item__tail {
    border-width: 1px;
    border-color: var(--rootColor);
  }
  .el-timeline-item__timestamp {
    color: var(--rootColor);
    font-size: 29px;
    font-weight: 600;
  }
  .el-timeline-item__wrapper {
    top: -14px;
  }
}
.mobile {
  padding: 0 10px;

 ::v-deep .el-timeline-item__timestamp {
    font-size: 16px;
    line-height: 24px;
  }
 ::v-deep .el-timeline-item__content span{
    font-size: 14px;
  }
}
</style>

<template>
  <!-- 手机版 -->
  <div v-if="device == 'mobile'" class="mobile footer">
    <!-- <el-carousel class="carousel-news h-pb-15" arrow="always">
      <el-carousel-item v-for="(item, index) in articles" :key="index">
        <div class="h-line-1 h-font-14 h-mb-5">{{ item.title }}</div>
        <div class="h-line-2 h-font-10">{{ item.remark }}</div>
      </el-carousel-item>
    </el-carousel> -->

    <div class="footer-left h-center">
      <p class="h-text-center" style="color: #ddd; font-size: 14px;"> {{baseConfig.company}} 版权所有 </p>
      <p class="h-text-center" style="color: #ddd; font-size: 14px;"> 公司地址：{{baseConfig.address}}</p>
      <p class="h-text-center" style="color: #ddd; font-size: 14px;"> 电子邮箱：{{baseConfig.email}}</p>
      <p class="h-text-center" style="color: #ddd; font-size: 14px;"> 客服热线：{{baseConfig.phone}}</p>
      <p class="h-text-center" style="color: #ddd; font-size: 14px;"> {{baseConfig.beian}}</p>
    </div>
  </div>
  <!-- 电脑版 -->
  <div v-else class="index-footer">
    <div class="container">
      <div class="footer">
        <div class="footer-left">
          <p style="font-size: 17px;margin-bottom: 8px;">{{baseConfig.company}} 版权所有 </p>
          <p>公司地址：{{baseConfig.address}} <span style="margin-left: 20px;">客服热线：{{baseConfig.phone}}</span></p>
          <p>电子邮箱：{{baseConfig.email}} </p>
          <p>
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" v-if="baseConfig.beian && baseConfig.beian.split(',')[0]"><img src="../assets/beian.png"/> {{baseConfig.beian.split(',')[0]}}</a>
            <a target="_blank" href="https://beian.miit.gov.cn/?token=4d777ef7-0546-4e7f-aad4-637d473c0946#/Integrated/index" style="margin-left:10px" v-if="baseConfig.beian"> {{baseConfig.beian.split(',')[1]}}</a>
          </p>
<!--          <p>电子邮箱：{{baseConfig.email}} <span style="margin-left: 62px;" v-if="baseConfig.beian"> {{baseConfig.beian}} </span></p>-->
          <!-- <p>客服热线：{{baseConfig.phone}} </p>
          <p v-if="baseConfig.beian">{{baseConfig.beian}} </p> -->
        </div>
        <!-- <div class="footer-right">
          <div class="fr-title">停水公告</div>
          <ul class="mt15">
            <li
              class="news-li mb4 text-overflow"
              v-for="(item, index) in news"
              :key="index"
              @click="bindGoNotice(item.pkSerial)"
            >
              <span>{{ item.title }}</span>
              <span class="mr2---2">{{ item.date }}</span>

            </li>
          </ul>
        </div> -->
        <div class="topnews">
          <div class="article-li d-flex">
            <div
              class="al-content flex"
              v-show="activeIndex == index"
              v-for="(item, index) in articles"
              :key="index"
            >
              <div class="title text-overflow mb15" @click="bindGoDetail(item)">{{ item.title }}</div>
              <div class="remark text-justify">{{ item.remark }}</div>
            </div>

            <div
              class="al-right d-flex flex-column justify-content-between align-items-end"
            >
              <div>
                <div class="arrow mr7" @click="gotoPagePre">
                  <span class="el-icon-arrow-left"></span>
                </div>
                <div class="arrow" @click="gotoPageNext">
                  <span class="el-icon-arrow-right"></span>
                </div>
              </div>
              <div class="f13">
                <strong>{{ activeIndex + 1 + "/" + articles.length }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @description
 * @author mai05009
 * @date 2021/03/05 09:45:43
 * @property
 * @event
 */

import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      news: [],
      activeIndex: 0,
      articles: [],

      // 自动切换计时
      interval: null,
    };
  },
  computed: {
    ...mapState({
      menuArray: state => state.menu.menuArray,
      menuTree: state => state.menu.menuTree,
      baseConfig: state => state.menu.baseConfig,
    }),
  },
  watch: {},
  created() {
    this.$http.get('/article/article/list', { params: {tag: 'hometop', auditStatus: 2,} }).then(res => {
      let data = res.rows || []
      data.sort((a, b) => {return a.createDate >= b.createDate? -1 : 1})
      this.articles = data.slice(0, 4)
      this.interval = setInterval(() => {this.gotoPageNext()}, 3000)
    })

    this.$http.get('/article/article/list', { params: { categoryId: 'BCDB154AE9230DAEE050007F01000156', auditStatus: 2,} }).then(res => {
      // mid: 'BCDB154AE9210DAEE050007F01000156'
      let data = res.rows || []
      data.sort((a, b) => {return a.createDate >= b.createDate? -1 : 1})
      data = data.slice(0, 5)
      data = data.map(item => {
        item.date = item.createDate.slice(0, 10)
        return item
      })
      this.news = data
      // console.log(res)
    })
  },
  mounted() {},
  methods: {
    gotoPagePre() {
      clearInterval(this.interval)
      let p = this.activeIndex - 1;
      this.goToPage(p);
    },
    gotoPageNext() {
      clearInterval(this.interval)
      let p = this.activeIndex + 1;
      this.goToPage(p);
    },
    goToPage(index) {
      let len = this.articles.length - 1;
      if (index < 0) {
        this.activeIndex = len;
      } else if (index > len) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
      this.interval = setInterval(() => {this.gotoPageNext()}, 3000)
    },
    // 跳详情
    bindGoDetail: function(item) {
      let aid = item.pkSerial
      let cid = item.categoryId
      let mid = this.menuArray.find(item => item.pkSerial == cid).pid
      this.$router.push({path: '/navigation', query: {mid, cid, aid}})
    },
    // 停水公告详情
    bindGoNotice: function(pk) {
      let mid = 'BCDB154AE9210DAEE050007F01000156'
      let cid = 'BCDB154AE9230DAEE050007F01000156'
      this.$router.push({path: '/navigation', query: {mid, cid, aid: pk}})
    }
  },
};
</script>
<style lang='scss' scoped>
@import "~@/styles/variables.scss";

.index-footer {
  background-color: rgba(#2b4d80, 0.9);
  padding: 15px 0;
  // min-height: 187px;
  .footer {
    color: #fff;
    display: flex;
    justify-content: space-between;

    .footer-left,
    .footer-right {
      text-align: left;
    }
    .footer-left {
      width: 40%;
      display: flex;
      flex-direction: column;
      // justify-content: space-between;
    }
    .footer-right {
      width: 30%;
    }
    .fr-title {
      font-size: 14px;
      padding-bottom: 7px;
      border-bottom: 1px solid #fff;
      // text-align: right;
    }
    .article-li {
      .al-content {
        overflow: hidden;
      }
      .title {
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        cursor: pointer;
      }
      .remark {
        font-size: 12px;
        line-height: 22px;
        max-height: 68px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        overflow: hidden;
      }
      .al-right {
        //   width: 66px;
        margin-left: 15px;
      }
      .arrow {
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 22px;
        border: 1px solid #fff;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
      }
    }

    .news-li {
      font-size: 12px;
      line-height: 16px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
    }
  }
}
.footer-left p {
  font-size: 13px;
  line-height: 22px;
}
.topnews {
  width: 50%;
}


.footer.mobile {
  background-color: rgba(#2b4d80, 0.9);
  padding: 10px 10px 20px;
  color: #fff;
  .footer-left p{
    line-height: 22px;
  }
}

.carousel-news ::v-deep .el-carousel__container{
  height: 65px;
}
.carousel-news::v-deep .el-carousel__item {
  box-sizing: border-box;
  padding: 0 55px;
}
.carousel-news::v-deep .el-carousel__arrow--left {
  left: 0;
}
.carousel-news::v-deep .el-carousel__arrow--right {
  right: 0;
}
</style>
